@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

#benefits {
    background: linear-gradient(180deg, #EDF8F8 0%, #FFFFFF 100%);
}

.benefits-section.section-wrapper {
}

.benefits-section.section-content {
    //background: blue;
}

.benefits-header {

    position: relative;
    color: #0E3B49;
    font-family: "Work Sans";
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    z-index: 2;

    @include for-phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 32px;
    }

    @include for-tablet-portrait-up {
        font-size: 40px;
        line-height: 44px;
    }

    @include for-desktop-up {
        font-size: 48px;
        line-height: 52px;
    }

}

#path {

    z-index: 1;
    display: none;

    @include for-desktop-up {
        display: inline;
        margin-top: -24px;
    }

}

#path-tablet {
    z-index: 1;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: inline;
        margin-top: -48px;
    }

    @include for-desktop-up {
        display: none;
    }

}

#path-mobile {

    @include for-phone-only {
        display: grid !important;
        grid-row-gap: 32px !important;
    }

    @include for-tablet-portrait-up {
        display: none;
    }

}

.benefit-item {
    display: grid;
    justify-content: space-between;
    height: max-content;
    max-height: 580px;
    position: relative;
    // border: 2px solid green;

    @include for-phone-only {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 30px;
        width: 100%;
        margin-top: 16px;
    }

    @include for-tablet-portrait-up {
        grid-template-columns: repeat(12, 1fr);
        //grid-gap: 30px;
        width: 340px; //50%;
    }

    @include for-desktop-up {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        width: 343px;
    }

    .description {

        //position: absolute;
        z-index: 2;

        @include for-phone-only {
            grid-column: 1/7;
            margin-top: 96px;
        }

        @include for-tablet-portrait-up {
            grid-column: 5/13;
            margin-top: 140px;
        }

        @include for-desktop-up {
            grid-column: 2/5;
            margin-top: 140px;
        }

        .header {

            color: #0E3B49;
            font-family: "Work Sans";
            font-weight: 600;
            letter-spacing: 0;

            @include for-phone-only {
                font-size: 22px;
                line-height: 25px;
                margin-bottom: 16px;
            }

            @include for-tablet-portrait-up {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 16px;
            }

        }

        p {
            color: #576464;
            font-family: "Work Sans";
            letter-spacing: 0;

            @include for-phone-only {
                font-size: 16px;
                line-height: 22px
            }

            @include for-tablet-portrait-up {
                font-size: 16px;
                line-height: 28px;
            }

            @include for-desktop-up {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    .image-wrapper {

        position: absolute;
        z-index: 1;

        @include for-phone-only {
            grid-column: 3/7;
        }

        @include for-tablet-portrait-up {
            grid-column: 1/10;
        }

        @include for-desktop-up {
            grid-column: 1/4;
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

}

foreignObject {
    width: 100%;
    height: 100%;
}

foreignObject:nth-of-type(1) {

    .benefit-item {

        @include for-tablet-portrait-up {
            transform: translate(0.52px, 107.08px);
        }

        @include for-desktop-up {
            transform: translate(43.54px, 96.5px);
        }
    }

}

foreignObject:nth-of-type(2) {

    .benefit-item {
        @include for-tablet-portrait-up {
            transform: translate(408.52px, 281.08px);
        }

        @include for-desktop-up {
            transform: translate(532.54px, 270.5px);
        }

        //.benefit-item {

        .description {

            @include for-tablet-portrait-up {
                grid-column: 3/13;
            }

            @include for-desktop-up {
                grid-column: 2/5;
            }

            margin-top: 192px;
        }

        img {
            margin-left: 8px;
            margin-top: 12px;
            transform: rotate(-6deg)
        }

    }

}

foreignObject:nth-of-type(3) {

    .benefit-item {

    @include for-tablet-portrait-up {
        transform: translate(25.52px, 562.08px);
    }

    @include for-desktop-up {
        transform: translate(129.54px, 565.5px);
    }

    //.benefit-item {

        .description {

            @include for-tablet-portrait-up {
                margin-top: 178px;
            }
        }

    }

}

foreignObject:nth-of-type(4) {

    .benefit-item {

    @include for-tablet-portrait-up {
        transform: translate(392.52px, 733.08px);
    }

    @include for-desktop-up {
        transform: translate(611.54px, 691.5px);
    }

    //.benefit-item {

        //@include for-phone-only {
        //    width: 100%;
        //    .description {
        //
        //        background: blue;
        //
        //        .image-wrapper {
        //            background: green;
        //            grid-column: 4/5;
        //        }
        //
        //    }
        //}

        @include for-tablet-portrait-up {

            width: 300px;

            .description {
                grid-column: 4/13;
                margin-top: 140px;
            }

            .image-wrapper {
                grid-column: 1/6;
            }
        }

        @include for-desktop-up {

            width: 380px;

            .description {
                grid-column: 2/5;
                padding-left: 20px;
                // margin-top: 209px;
            }

            .image-wrapper {
                grid-column: 1/3;
            }
        }

    }

}

.benefit-item:nth-of-type(2) {

    @include for-phone-only {
        img {
            transform: rotate(-6deg)
        }
    }
}

.benefit-item:nth-of-type(4) {
    @include for-phone-only {
        width: 100%;

        .description {
            margin-top: 128px;
        }

        .image-wrapper {
            grid-column: 4/7;
            text-align: right;

            img {
                width: 75%;
            }
        }

    }
}