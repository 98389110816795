@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.sectionWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 56px;
    padding-bottom: 56px;

    @include for-phone-only {
        padding-bottom: 96px;
    }

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-tablet-portrait-up {
        padding-top: 100px;
        padding-left: 24px;
        padding-right: 24px;
    }

    @include for-tablet-portrait-up {
        padding-top: 20px;
    }

    @include for-desktop-up {
        padding-top: 40px;
    }
}

.header {
    color: #0E3B49;
    font-family: "Work Sans";
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;

    @include for-phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    @include for-tablet-portrait-up {
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        margin-bottom: 24px;
    }

    @include for-desktop-up {
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 24px;
    }

}

.subheader {
    height: 56px;
    width: 100%;
    max-width: 830px;
    color: #576464;
    font-family: "Work Sans";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;

    @include for-phone-only {
        font-size: 16px;
        line-height: 24px;
        max-width: 320px;
        height: unset;
    }
}

.identityTypes {
    margin-top: 64px;
    
    @include for-phone-only {
        max-width: 320px;
        margin-top: 24px;
    }
}

.identityTypes > .typeHeaders {
    display: flex;
    flex-direction: row;
    gap: 36.5px;
    justify-content: space-between;

    @include for-phone-only {
        gap: 24px;
    }

    @include for-tablet-portrait-up {
        gap: 18px;
    }

    @include for-tablet-landscape-up {
        gap: 36.5px;
    }
}

.typeHeaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .typeHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 78px;
        width: 242px;
        border-radius: 7px;
        background-color: #FFF5F2;

        @include for-phone-only {
            width: 86px;
        }
    }

    .typeHeaderArrow {
        height: 20px;
        width: 20px;
        transform: translateY(-50%) rotate(45deg) ;
        border-radius: 2px;
        background-color: #FFF5F2;
        visibility: hidden;

        @include for-phone-only {
            height: 16px;
            width: 16px;
        }
    }

    .typeHeaderLabel {
        color: #F08769;
        font-family: "Work Sans";
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;

        @include for-phone-only {
            font-size: 13px;
            max-width: 60%;
        }
    }
}

.typeHeaderWrapper.active {
    .typeHeaderArrow {
        visibility: visible;
    }
}

.typeHeaderWrapper:nth-of-type(2) {
    .typeHeader {
        background-color: #EDF8F8;
    }
    .typeHeaderArrow {
        background-color: #EDF8F8;
    }
    .typeHeaderLabel {
        color: #265E5F;
    }
}

.typeHeaderWrapper:nth-of-type(3) {
    .typeHeader {
        background-color: #F4F4FF;
    }
    .typeHeaderArrow {
        background-color: #F4F4FF;
    }
    .typeHeaderLabel {
        color: #6F729C;
    }
}

.identities {
    padding-top: 22px;
    padding-bottom: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.identity {
    display: flex;
    flex-direction: row;
    gap: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 62px 120px;
    // background: pink;

    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 48px 0 rgba(255,245,242,0.5);

    @include for-phone-only {
        padding: 0px 40px;
        width: 100%;
    }

    @include for-tablet-portrait-up {
        padding: 62px 20px;
    }

    @include for-desktop-up {
        padding: 62px 120px;
    }


    img {
        height: 200px;
        width: 300px;

        @include for-phone-only {
            display: none;
        }
    }

}

.identity.second {
    box-shadow: 0 10px 48px 0 rgba(237,248,248,0.5);
}

.identity.third {
    box-shadow: 0 10px 48px 0 rgba(244,244,255,0.5);
}

.identity > .description {

    display: flex;
    flex-direction: column;
    gap: 16px;

    max-width: 350px;

    .label {
        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        border-radius: 4px;
        background-color: #F08769;
        padding: 8px;
        width: max-content;
    }

    .header1 {
        color: #0E3B49;
        font-family: "Work Sans";
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
    }

    .header2 {
        color: #576464;
        font-family: "Work Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 28px;
    }

}

.identity.second > .description {
    .label {
        background-color: #265E5F;
    }
}

.identity.third > .description {
    .label {
        background-color: #6F729C;
    }
}

.invisible {
    display: none;
}

.ctaButton {
    color: #EDF8F8 !important;
    font-family: "Work Sans" !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    border-radius: 7px !important;
    background-color: #0E3B49 !important;
    transition: 0.3s !important;

    @include for-phone-only {
        height: 64px;
        width: 100%;
        box-shadow: 0 24px 48px 0 rgba(14,59,73,0.1);
        font-size: 14px;
        line-height: 16px;
    }

    @include for-tablet-portrait-up {
        height: 74px;
        width: 298px;
        font-size: 14px;
        line-height: 16px;
        box-shadow: 0 24px 48px 0 rgba(14,59,73,0.1);
    }

    @include for-desktop-up {
        height: 74px;
        width: 298px;
        font-size: 14px;
        line-height: 16px;
        box-shadow: 0 24px 48px 0 rgba(14, 59, 73, 0.1);
    }

}

.ctaButton:hover {
    font-size: 15px !important;
    line-height: 20px !important;
    background-color: #f99066 !important;
}