@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.subscribe-section.section-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 764px;
}

.subscribe-section.section-content {
    padding-bottom: 0px;

    //text-align: center;
    overflow-x: hidden;
    text-align: center;

    @include for-tablet-portrait-up {
        padding-top: 128px;
    }

    @include for-desktop-up {
        padding-top: 128px;
    };
}

.subscribe-header {
    color: #0E3B49;
    font-family: "Work Sans";
    font-weight: 600;
    letter-spacing: 0;

    @include for-phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    @include for-tablet-portrait-up {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 24px;
    }

    @include for-desktop-up {
        line-height: 52px;
        font-size: 48px;
        //margin-top: 88px;
        margin-bottom: 24px;
    }
}

.subscribe-subheader {

    color: #576464;
    font-family: "Work Sans";
    text-align: center;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 40px;

    @include for-phone-only {
        margin-bottom: 24px;
        font-size: 18px !important;
    }

    //@include for-tablet-portrait-up {

    //}

}

.subscribe-column-content {
    /*background: blue;*/
    /*color: #F9FAFB;*/
    /*height: 100px !important;*/
    /*display: flex !important;*/
    /*flex-direction: column;*/
    /*justify-content: center !important;*/
    text-align: center;
    /*center;*/
    padding-top: 24px !important;
    padding-bottom: 24px !important;
}

.subscribe-section-cta-wrapper {
    margin: 24px 0px;
    text-align: left;
    /*width: 100%;*/
    /*display: flex;*/
    /*justify-content: center;*/
}

.subscribe-section-form {

    display: grid;
    grid-gap: 30px;
    margin: 40px 0px;

    @include for-phone-only {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
    }

    @include for-tablet-portrait-up {
        grid-template-columns: repeat(6, 1fr);
    }

    @include for-desktop-up {
        grid-template-columns: repeat(12, 1fr);
    }

    .subscribe-input {

        input {
            box-sizing: border-box;
            border: 1px solid rgba(38, 94, 95, 0.24);
            border-radius: 7px;
            background-color: #FFFFFF;
            box-shadow: 0 12px 24px -2px rgba(14, 59, 73, 0.1) !important;
            //box-shadow: 0 16px 16px 4px rgba(14, 59, 73, 0.1) !important;
            //opacity: 0.45;
            color: #576464;
            font-family: "Work Sans";
            letter-spacing: 0;
            //text-align: center;
            padding: 32px;
            border: 0px;

            @include for-tablet-portrait-up {
                box-shadow: 0 16px 16px 4px rgba(14, 59, 73, 0.1) !important;
            }
        }

        @include for-phone-only {
            grid-column: 1/5;
            height: 75px;
            font-size: 16px;
            line-height: 19px;
        }

        @include for-tablet-portrait-up {
            grid-column: 1/4;
            height: 75px;
            font-size: 16px;
            line-height: 19px;
            padding-left: 16px;
        }

        @include for-desktop-up {
            grid-column: 3/8;
            height: 75px;
            font-size: 16px;
            line-height: 19px;
            padding-left: 0px;
        }

        input:hover {
            box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
                        0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
        }

        input:focus {
            border: 0px;
            color: #F08769 !important;

            @include for-tablet-portrait-up {
                box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
                0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
            }
        }

    }


    ::placeholder {
        //opacity: 1;
      opacity: 0.45;
        //z-index: 2;
        color: #576464 !important;
    }



    .subscribe-section-cta-button {

        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: "Work Sans" !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;

        @include for-phone-only {
            grid-column: 1/5;
            height: 75px;
            font-size: 16px;
            line-height: 19px;
        }

        @include for-tablet-portrait-up {
            grid-column: 4/7;
            height: 75px;
            font-size: 14px;
            line-height: 16px;
        }

        @include for-desktop-up {
            grid-column: 8/11;
            height: 75px;
            font-size: 14px;
            line-height: 16px;
        }

    }

    .subscribe-section-cta-button:hover {
        color: white;
        font-size: 15px !important;
        line-height: 20px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;
    }
}

.subscribe-info {
    color: #F08769;
    font-family: "Work Sans";
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;

    //@include for-tablet-portrait-up {
        font-size: 16px;
        line-height: 28px;
    //}

    strong {
        font-weight: 500;
    }
}

#svg-subscribe {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    max-width: 1361px;
    //min-width: 1200px;
    display: none;

    @include for-desktop-up {
        display: inline;
    }

}

#svg-subscribe-tablet {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    width: 100%;
    display: none;
    max-width: 1361px;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: inline;
    }

    @include for-desktop-up {
        display: none;
    }
}

#svg-subscribe-mobile {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    width: 100%;
    display: none;
    max-width: 1361px;
    display: none;

    @include for-phone-only {
        display: inline;
    }

}


.subscribe-output-wrapper {

    .subscribe-error {
        color: #fd4949;
        font-size: 16px;
        margin: 12px 0px;
    }

    .subscribe-success {
        color: #037860;
        font-size: 18px;
        margin: 24px 0px;
    }

}


//.subscribe-column-delimiter {
//    /*background: pink;*/
//    display: flex !important;
//    justify-content: center !important;
//    align-items: center;
//    justify-items: center !important;
//}
//
//.subscribe-column-header {
//    display: inline-block;
//    /*margin: 0 auto;*/
//    font-size: 23px;
//    font-weight: 400;
//    /*background: pink;*/
//}
//
//.subscribe-column-text {
//    font-size: 19px;
//    line-height: 29px;
//    font-weight: 200;
//    padding: 36px 0px 0px 0px;
//    text-align: left;
//    width: 85%;
//    /*background: yellow;*/
//    display: inline-block;
//}
//
//.subscribe-column-text > strong {
//    font-weight: 300;
//}
//
//.subscribe-section-desc {
//    font-size: 31px;
//    line-height: 48px;
//    font-weight: 200;
//    margin: 48px 0px;
//}
//
//.subscribe-section-img {
//    /*max-height: 20%;*/
//    /*height: auto;*/
//    /*margin: 96px 0px;*/
//    /*height: 35vh;*/
//    /*height: 40vw !important;*/
//    /*margin: 10vh 0px;*/
//    /*height: auto !important;*/
//    max-width: 15vh !important;
//    object-fit: contain;
//    margin: 0 auto;
//}
//
//.subscribe-section-desc-bottom {
//    font-size: 31px;
//    line-height: 48px;
//    font-weight: 200;
//    margin: 48px 0px;
//}
//
//.subscribe-section-desc-bottom > strong {
//    font-weight: 400;
//}


//.subscribe-section-form .ui.input  {
//    margin-top: 24px !important;
//    margin-bottom: 16px;
//    width: 60%;
//    min-width: 256px;
//    font-size: 19px;
//    font-family: 'Work Sans', sans-serif !important;
//}
//
//.subscribe-section-form > .info-email {
//    margin: 48px 16px 0px 16px;
//    font-size: 17px;
//    line-height: 23px;
//    color: #F08769;
//    font-weight: 300;
//}
//
//.subscribe-section-form > .info-email > span {
//    font-weight: 400;
//}
//
//.subscribe-section-form .ui.input:placeholder {
//    font-family: 'Work Sans', sans-serif !important;
//
//}
//
//.subscribe-section-cta-button {
//    font-family: 'Work Sans', sans-serif !important;
//    /*margin: 0 auto 40vh auto !important;*/
//    font-size: 18px !important;
//    line-height: 25px !important;
//    font-weight: 500 !important;
//    color: #F9FAFB !important;
//    background: #172D39  !important;
//    border-radius: 2px !important;
//    width: 60%;
//    min-width: 256px;
//}
//
//.subscribe-section-cta-button:hover {
//    color: #EDF8F8 !important;
//    background: #163E4A !important;
//}
//
//.mobile-only {
//    display: none;
//}
//
//.subscribe-error {
//    color: red;
//    font-size: 16px;
//    margin: 12px 0px;
//}
//
//.subscribe-success {
//    color: #037860;
//    font-size: 27px;
//    margin: 48px 0px;
//}

.agreement-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    margin-top: 0px;
    padding: 0px 4px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    grid-column: 3/8;
    margin-top: -16px;

    @include for-phone-only {
        grid-column: 1/8;
    }

    @include for-tablet-portrait-up {
        grid-column: 1/8;
        padding-left: 16px;
    }

    @include for-desktop-up {
        grid-column: 3/8;
        padding-left: 4px;
    }

    color: #505A5E;

    input {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        background: #FFFFFF;       
        border: 1px solid #EAEBEB;
        border-radius: 7px; 
    }

    input:hover {
        cursor: pointer;
    }
}

.agreement-wrapper.error {
    border: 1px solid red;
    border-radius: 7px; 
}