@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}


#protection {

}

.protection-section.section-wrapper {
    position: relative;
    overflow-x: hidden;

    @include for-tablet-portrait-up {
        padding-bottom: 0px;
    }
}

.protection-section.section-content {
    width: 100%;
    display: grid;
    grid-gap: 30px;
    position: relative;
    z-index: 3;

    @include for-tablet-portrait-up {
        grid-template-columns: repeat(4, 1fr);
    }

    @include for-tablet-portrait-up {
        grid-template-columns: repeat(6, 1fr);
    }

    @include for-desktop-up {
        grid-template-columns: repeat(12, 1fr);
    }
}

.protection-section {

    .header {
        color: #0E3B49;
        font-family: "Work Sans";
        font-weight: 600;
        letter-spacing: 0;
        z-index: 1;
        position: relative;

        @include for-phone-only {
            grid-column: 1/5;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
        }

        @include for-tablet-portrait-up {
            grid-column: 1/3;
            font-size: 40px;
            line-height: 44px;
            max-width: 210px;
        }

        @include for-desktop-up {
            //grid-column: 2/6;
            font-size: 48px;
            line-height: 52px;
            grid-column: 2/6;
            max-width: 100%;
        }

        .orange-text {
            color: #FF977A;
        }

    }

    .header-image-wrapper {
        position: absolute;
        width: 100%;

        @include for-phone-only {
            grid-column: 1/5;
            text-align: center;
        }

        @include for-tablet-portrait-up {
            grid-column: 1/3;
        }

        @include for-desktop-up {
            grid-column: 2/6;
            margin-left: -37px;
        }

        img {
            margin-top: 210px;
            width: 100%;
            object-fit: cover;

            @include for-phone-only {
                margin-top: 110px;
                width: 55%;
            }
        }
    }

    .column-middle {
        margin-top: 40px;
        display: grid;
        row-gap: 40px;

        @include for-phone-only {
            grid-column: 1/5;
            margin-top: 112px;
        }

        @include for-tablet-portrait-up {
            grid-column: 3/5;
            grid-template-rows: repeat(2, min-content);
            margin-bottom: 155px;
        }

        @include for-desktop-up {
            grid-column: 6/9;
            grid-template-rows: repeat(3, min-content);
            margin-bottom: 255px;
        }

    }

    .column-right {

        display: grid;
        grid-template-rows: repeat(3, min-content);
        row-gap: 40px;

        @include for-phone-only {
            grid-column: 1/5;
        }

        @include for-tablet-portrait-up {
            margin-top: 101px;
            grid-column: 5/7;
            grid-template-rows: repeat(2, min-content);
        }

        @include for-desktop-up {
            grid-column: 9/12;
            grid-template-rows: repeat(3, min-content);
        }
    }

    .item {

        .icon {
            margin-bottom: 24px;
        }

        .header {
            color: #265E5F;
            font-family: "Work Sans";
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: left;

            @include for-phone-only {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 16px;
                //text-align: left;
            }

            @include for-tablet-portrait-up {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 16px;
            }

        }

        .description {
            color: #576464;
            font-family: "Work Sans";
            letter-spacing: 0;

            @include for-phone-only {
                font-size: 16px;
                line-height: 28px;
            }

            @include for-tablet-portrait-up {
                font-size: 16px;
                line-height: 28px;
            }

        }

    }

}

#protection-svg {

    display: none;

    @include for-desktop-up {
        display: inline;
        position: absolute;
        //bottom: 0px;
        bottom: 96px;
        z-index: 1;
    }

}

#protection-svg-mobile {

    display: none;

    @include for-phone-only {
        display: inline;
        position: absolute;
        bottom: 0px;
        z-index: 1;
        margin-bottom: 551px;
    }

}

#protection-svg-tablet {

    display: none;

    @include for-tablet-portrait-up {
        display: inline;
        position: absolute;
        bottom: 0;
        z-index: 1;
        margin-bottom: 155px
    }

    @include for-desktop-up {
        display: none;
    }

}

#protection-svg-top {

    display: none;

    @include for-desktop-up {
        display: inline;
        position: absolute;
        //bottom: 0px;
        bottom: 96px;
        z-index: 2;
        margin-bottom: 118px;
    }
}

#protection-svg-top-tablet {

    display: none;

    @include for-tablet-portrait-up {
        display: inline;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        margin-bottom: 264px;
    }

    @include for-desktop-up {
        display: none;
    }

}

#protection-svg-top-mobile {

    display: none;

    @include for-phone-only {
        display: inline;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        margin-bottom: 546px;
    }

}