@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}


#how-to-use {
  width: 100%;
  background: linear-gradient(180.55deg, #265E5F 0%, #265E5F 29.93%, #FFFDFA 73.84%, #FFFDFA 91.9%, #FFFFFF 100%);

  @include for-phone-only {
    background: linear-gradient(180.55deg, #265E5F 0%, #265E5F 29.93%, #FFFDFA 73.84%, #FFFDFA 91.9%, #FFFFFF 100%);
    //background: linear-gradient(180.55deg, #265E5F 0%, #265E5F 40.93%, #FFFDFA 63.84%, #FFFDFA 91.9%, #FFFFFF 100%);
  }

}

.demo-section.section-wrapper {
  position: relative;
}

.demo-section.section-content {

  display: grid;
  grid-template-rows: repeat(4, min-content);
  column-gap: 30px;
  row-gap: 48px;

  @include for-phone-only {
    grid-template-columns: repeat(4, 1fr);
  }

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 40px;
  }

  @include for-desktop-up {
    grid-template-columns: repeat(12, 1fr);
  }

}


.demo-header {

  color: #FFFFFF;
  font-family: "Work Sans";
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  grid-row: 1;

  @include for-phone-only {
    grid-column: 1/5;
    font-size: 32px;
    line-height: 36px;
  }

  @include for-tablet-portrait-up {
    grid-column: 1/7;
    font-size: 40px;
    line-height: 44px;
  }

  @include for-desktop-up {
    grid-column: 1/13;
    font-size: 48px;
    line-height: 52px;
  }


}

.demo-video {
  width: 100%;
  height: 384px;
  //background: grey;
  grid-row: 2;

  @include for-phone-only {
    height: 175px;
    grid-column: 1/5;
  }

  @include for-tablet-portrait-up {
    grid-column: 1/7;
  }

  @include for-desktop-up {
    grid-column: 3/11;
  }


}

.demo-steps {
  width: 100%;
  //height: 156px;
  //background: pink;
  grid-row: 3;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include for-phone-only {
    flex-direction: column;
    grid-column: 1/5;
  }

  @include for-tablet-portrait-up {
    grid-column: 1/7;
  }

  @include for-desktop-up {
    grid-column: 2/12;
  }

  .demo-step {

    //background: green;
    z-index: 2;

    @include for-tablet-portrait-up {
      min-width: 200px;
      flex: 1;
    }

    @include for-desktop-up {
      flex: unset;
      min-width: 255px;
    }

    .step-header {

      color: #5EDED2;
      font-family: "Work Sans";
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;

      @include for-phone-only {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 14px;
        text-transform: uppercase;
      }

      @include for-tablet-portrait-up {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 16px;
      }

    }

    .step-description {

      color: #FFFFFF;
      font-family: "Work Sans";
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;

      @include for-phone-only {
        font-size: 20px;
        line-height: 28px;
        font-weight: 300;
      }

      @include for-tablet-portrait-up {
        font-size: 20px;
        line-height: 28px;
        //padding: 0 16px;
      }

      @include for-desktop-up{
        padding: 0;
      }

    }

  }

}

.demo-step:nth-of-type(2) {

  @include for-phone-only {
    margin-bottom: 55px;
    margin-top: 55px;
  }

}

.demo-cta-wrapper {
  grid-row: 4;

  text-align: center;

  @include for-phone-only {
    grid-column: 1/5;
    padding-bottom: 24px;
  }

  @include for-tablet-portrait-up {
    grid-column: 1/7;
    padding-top: 16px;
  }

  @include for-desktop-up {
    grid-column: 1/13;
  }

}

.demo-cta-button {

  border-radius: 7px !important;
  background-color: #EDF8F8 !important;
  color: #0E3B49 !important;
  font-family: "Work Sans" !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;

  @include for-phone-only {
    height: 62.56px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  @include for-tablet-portrait-up {
    height: 74px;
    width: 282px;
    font-size: 14px;
    line-height: 16px;
  }
}

.demo-path-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  //border: solid 2px blue;
  text-align: center;
}

#svg-demo-path {
  width: 85%;
  height: 100%;
  //border: solid 2px red;

  @include for-phone-only {
    display: none;
  }

}

#svg-path:hover {
  animation: dash 2s linear forwards;
}

@keyframes dash {
  0% { stroke-dashoffset: 0}
  100% { stroke-dashoffset: 80}
}

#svg-demo-path-mobile {
  //width: 85%;
  //height: 100%;
  //border: solid 2px red;

  @include for-phone-only {
    //width: 500px;
    //transform: rotate(90deg);
  }

  @include for-tablet-portrait-up {
    display: none;
  }

}


.game-changer-section.section-wrapper {

  position: relative;

}

.game-changer-section.section-content {

  //display: grid;
  //grid-template-rows: repeat(4, min-content);
  //column-gap: 30px;
  //row-gap: 48px;

  @include for-phone-only {
    //grid-template-columns: repeat(4, 1fr);
    padding-top: 0px;
    //height: 2000px;
  }

  @include for-tablet-portrait-up {
    //grid-template-columns: repeat(6, 1fr);
    //padding-top: 0px;
  }

  @include for-desktop-up {
    //grid-template-columns: repeat(12, 1fr);
    //padding-top: 0px;
  }

}

.game-changer-descriptions-wrapper {

  margin-top: 48px;

  @include for-phone-only {
    height: 900px;
    //background: pink;
    margin-top: 0px;
  }

  @include for-tablet-portrait-up {
    width: 100%;
  }

}

.game-changer-descriptions-content {
  text-align: center;
  @include for-phone-only {
    //overflow-x: scroll;
    //border: 1px solid red;
  }
}

.game-changer-descriptions-content-scroll {
  @include for-phone-only {
    //overflow-x: scroll;
    overflow-x: hidden;
    //border: 1px solid blue;
  }
}

.sticky {
  position: sticky;
  top: 32px;
}

.game-changer-header {

  color: #FFFFFF;
  font-family: "Work Sans";
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  //position: sticky;
  //bottom: 2rem;


  @include for-phone-only {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
  }

  @include for-tablet-portrait-up {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 32px;
  }

  @include for-desktop-up {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 80px;
  }

}


.game-changer-img {

  //position: sticky;
  margin: 48px auto;
  transform: translate(16px, 0);
  //margin-top: 48px;
  //margin-bottom: 48px;


  @include for-phone-only {
    //grid-column: 1/5;
    //width: 730px;
    //height: 253px;

    width: 584px;
    margin: 0px 20% 48px;
  }

  @include for-tablet-portrait-up {
    //grid-column: 1/7;
    width: 100%;
  }

  @include for-desktop-up {
    //grid-column: 3/12;
    width: 80%;
  }



}

.game-changer-img.ui.image {
  @include for-phone-only {
    max-width: unset;
  }
}

.game-changer-descriptions {
  width: 100%;
  //grid-row: 3;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include for-phone-only {
    width: 730px;
    position: sticky;
    top: 32px;
  }

  //@include for-phone-only {
  //  grid-column: 1/5;
  //}
  //
  //@include for-tablet-portrait-up {
  //  grid-column: 1/7;
  //}
  //
  //@include for-desktop-up {
  //  grid-column: 2/12;
  //}

  .game-changer-desc {
    //flex: 1;

    //background: green;
    z-index: 2;
    text-align: center;

    @include for-desktop-up {
      width: 265px;
    }

    .desc-header {

      color: #B4D0F7;
      font-family: "Work Sans";
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;
      //text-transform: uppercase;

      @include for-phone-only {
          font-size: 22px;
          letter-spacing: 0;
          line-height: 25px;
          margin-bottom: 28px;
          color: #7393BF;
      }

      @include for-tablet-portrait-up {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
      }

    }

    .desc-text {

      color: #576464;
      font-family: "Work Sans";
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;

      @include for-phone-only {
        font-size: 16px;
        line-height: 28px;
      }

      @include for-tablet-portrait-up {
        font-size: 14px;
        line-height: 28px;
      }

      @include for-desktop-up {
        font-size: 16px;
        line-height: 28px;
      }

    }

    .desc-recommend {

      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #4b4848;
      opacity: 0.25;
      font-family: "Work Sans";
      height: 56px;

      img {
        // margin-left: 12px;
        height: 28px;
      }

      @include for-desktop-up {
        flex-direction: row;
      }

    }

    .no-opacity {
      opacity: 1;
    }

    .desc-recommend:hover {
      opacity: 1;
      cursor: pointer;
    }

  }

}

.game-changer-desc:nth-of-type(2) > .desc-header {
  color: #657777;
}

.game-changer-desc:nth-of-type(3) > .desc-header {
  color: #959286;;
}

.game-changer-cta-wrapper {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, min-content);
  margin-top: 16px;

  //@include for-phone-only {
  //  margin-top: 2000px;
  //}

  .game-changer-cta-text {
    color: #576464;
    font-family: "Work Sans";
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    grid-row: 1;
    margin-top: 60px;

    @include for-phone-only {
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 40px;
      grid-column: 1/13;
    }

    @include for-tablet-portrait-up {
      font-size: 20px;
      margin-bottom: 40px;
      grid-column: 2/12;
    }

    @include for-desktop-up {
      grid-column: 3/11;
    }

    .orange {
      color: #F08769;
    }

    .bold {
      font-weight: 600;
    }

  }

  a {
    grid-row: 2;
    grid-column: 1/13;
  }

  .game-changer-section-cta-button {
    color: #EDF8F8 !important;
    font-family: "Work Sans" !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    border-radius: 7px !important;
    background-color: #0E3B49 !important;

    width: 255px;

    @include for-phone-only {
      height: 64px;
      width: 100%;
      box-shadow: 0 24px 48px 0 rgba(14,59,73,0.1);
      font-size: 14px;
      line-height: 16px;
    }

    @include for-tablet-portrait-up {
      height: 74px;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 24px 48px 0 rgba(14,59,73,0.1);
    }

    @include for-tablet-portrait-up {
      height: 74px;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 24px 48px 0 rgba(14, 59, 73, 0.1);
    }

  }

  //.game-changer-section-cta-button:hover {
  //  color: #EDF8F8 !important;
  //  background: #163E4A !important;
  //}

}

.phone-only {
  display: none;

  @include for-phone-only {
    display: inline;
  }
}

.tablet-only {
  display: none;

  @include for-tablet-portrait-up {
    display: inline;
  }

  @include for-desktop-up {
    display: none;
  }
}

.tablet2-only {
  display: none;

  @include for-tablet-portrait-up {
    display: inline;
  }

  @include for-desktop-up {
    display: none;
  }
}


.desktop-only {
  display: none;

  @include for-desktop-up {
    display: inline;
  }
}
