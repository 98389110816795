/*.game-changer-section-wrapper {*/
/*    padding: 0px 64px;*/
/*    background: white !important;*/
/*    font-family: 'Work Sans', sans-serif !important;*/
/*    color: #172D39;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    !*padding: 10vh 20vh;*!*/
/*}*/

/*.game-changer-header {*/
/*    font-size: 64px;*/
/*    line-height: 72px;*/
/*    font-weight: 200;*/
/*    color: #172D39;*/
/*    margin: 0 auto;*/
/*    display: inline-block;*/
/*}*/

/*.game-changer-header > span {*/
/*    font-weight: 400;*/
/*}*/

/*.game-changer-column-content {*/
/*    !*background: blue;*!*/
/*    !*color: #F9FAFB;*!*/
/*    !*height: 100px !important;*!*/
/*    !*display: flex !important;*!*/
/*    !*flex-direction: column;*!*/
/*    !*justify-content: center !important;*!*/
/*    text-align: center;*/
/*    !*center;*!*/
/*    padding-top: 24px !important;*/
/*    padding-bottom: 24px !important;*/
/*}*/

/*.game-changer-column-delimiter {*/
/*    !*background: pink;*!*/
/*    display: flex !important;*/
/*    justify-content: center !important;*/
/*    align-items: center;*/
/*    justify-items: center !important;*/
/*}*/

/*.game-changer-column-header {*/
/*    display: inline-block;*/
/*    !*margin: 0 auto;*!*/
/*    font-size: 23px;*/
/*    font-weight: 500;*/
/*    !*background: pink;*!*/
/*}*/

/*.game-changer-column-text {*/
/*    font-size: 19px;*/
/*    line-height: 29px;*/
/*    font-weight: 200;*/
/*    padding: 36px 0px 0px 0px;*/
/*    text-align: left;*/
/*    width: 85%;*/
/*    !*background: yellow;*!*/
/*    display: inline-block;*/
/*}*/

/*.game-changer-column-text > strong {*/
/*    font-weight: 200;*/
/*}*/

/*.game-changer-section-desc {*/
/*    font-size: 31px;*/
/*    line-height: 48px;*/
/*    font-weight: 200;*/
/*    margin: 48px 0px;*/
/*}*/

/*.game-changer-section-img {*/
/*    !*max-height: 20%;*!*/
/*    !*height: auto;*!*/
/*    !*margin: 96px 0px;*!*/
/*    !*height: 35vh;*!*/
/*    !*width: 60vw;*!*/
/*    width: 80%;*/
/*    margin: 10vh auto;*/
/*}*/

/*.game-changer-section-desc-bottom {*/
/*    font-size: 31px;*/
/*    line-height: 48px;*/
/*    font-weight: 200;*/
/*    margin: 48px 0px;*/
/*}*/

/*.game-changer-section-desc-bottom > strong {*/
/*    font-weight: 400;*/
/*}*/

/*.game-changer-section-cta-wrapper {*/
/*    margin: 24px 0px;*/
/*    text-align: left;*/
/*    !*width: 100%;*!*/
/*    !*display: flex;*!*/
/*    !*justify-content: center;*!*/
/*}*/

/*.game-changer-section-cta-button {*/
/*    font-family: 'Work Sans', sans-serif !important;*/
/*    !*margin: 0 auto 40vh auto !important;*!*/
/*    font-size: 18px !important;*/
/*    line-height: 25px !important;*/
/*    font-weight: 500 !important;*/
/*    color: #F9FAFB !important;*/
/*    background: #172D39  !important;*/
/*    border-radius: 2px !important;*/
/*}*/

/*.game-changer-section-cta-button:hover {*/
/*    color: #EDF8F8 !important;*/
/*    background: #163E4A !important;*/
/*}*/

/*.game-changer-grid {*/
/*    width: 90%;*/
/*}*/

/*!*------------------------------------------*/
/*  Responsive Grid Media Queries - 1280, 1024, 768, 480*/
/*   1280-1024   - desktop (default grid)*/
/*   1024-768    - tablet landscape*/
/*   768-480     - tablet*/
/*   480-less    - phone landscape & smaller*/
/*--------------------------------------------*!*/


/*@media all and (min-width: 1024px) and (max-width: 1280px) {*/

/*}*/

/*@media all and (min-width: 768px) and (max-width: 1024px) {*/

/*    .game-changer-header {*/
/*        font-size: 44px;*/
/*        line-height: 54px;*/
/*    }*/

/*}*/

/*@media all and (min-width: 480px) and (max-width: 768px) {*/

/*    .game-changer-section-wrapper {*/
/*        padding: 0px;*/
/*    }*/

/*    .game-changer-header {*/
/*        font-size: 34px;*/
/*        line-height: 48px;*/
/*    }*/

/*    .game-changer-column-header {*/
/*        font-size: 21px;*/
/*    }*/

/*    .game-changer-column-text {*/
/*        font-size: 19px;*/
/*        line-height: 25px;*/
/*        font-weight: 200;*/
/*        padding: 16px 0px 0px 0px;*/
/*    }*/

/*    .game-changer-column-cta {*/
/*        padding: unset;*/
/*        margin-top: 16px;*/
/*        !*-top: 50px;*!*/
/*        !*margin-top: 16px !important;*!*/
/*    }*/

/*}*/

/*@media all and (max-width: 480px) {*/

/*    .game-changer-section-wrapper {*/
/*        padding: 0px;*/
/*    }*/

/*    .game-changer-header {*/
/*        font-size: 44px;*/
/*        line-height: 54px;*/
/*    }*/

/*    .game-changer-section-img {*/
/*        width: 100%;*/
/*        !*margin: 10vh     auto;*!*/
/*    }*/

/*    .game-changer-column-header {*/
/*        font-size: 21px;*/
/*    }*/

/*    .game-changer-column-text {*/
/*        font-size: 16px;*/
/*        line-height: 22px;*/
/*        font-weight: 200;*/
/*        padding: 16px 0px 0px 0px;*/
/*    }*/

/*    .game-changer-grid {*/
/*        width: 100%;*/
/*    }*/

/*    .game-changer-column-cta {*/
/*        padding: unset;*/
/*        margin-top: 16px;*/
/*        !*-top: 50px;*!*/
/*        !*margin-top: 16px !important;*!*/
/*    }*/

/*    .game-changer-section-cta-wrapper {*/
/*        margin: 24px 0px 12px 0px;*/
/*        text-align: left;*/
/*    }*/

/*}*/