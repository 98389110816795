.wrapper {
    background: linear-gradient(180.55deg, #265E5F 0%, #265E5F 29.93%, #FFFDFA 73.84%, #FFFDFA 91.9%, #FFFFFF 100%);
    height: 100vh;
    width: 100%;
}

.obj {
    background: yellow;
    height: 100px;
    width: 50%;
    position: sticky;
    top: 2rem
}