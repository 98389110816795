@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.App {
  width: 100%;
  margin: 0 auto;
  font-family: 'Work Sans' !important;
  //overflow-x: hidden;
}

.section-wrapper {
  width: 100%;
  margin: 0 auto;

  @include for-tablet-portrait-up {
    max-width: 768px;
  }

  @include for-desktop-up {
    max-width: 1440px;
  }

}

.section-content {
  margin: 0 auto;
  width: 100%;

  @include for-phone-only {
    //max-width: 320px;
    max-width: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include for-tablet-portrait-up {
    max-width: 690px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include for-desktop-up {
    max-width: 1110px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

}

//svg:not(:root) {
//  overflow: initial !important;
//}

//.section-content-wrapper {
//  /*max-width: 1554px !important;*/
//  /*background: yellow !important;*/
//  margin: 0 auto !important;
//  /*padding: 10vh 10vw !important;*/
//  padding: 7vh 32px!important;
//  /*padding-bottom: 10vh !important;*/
//  max-width: 1600px;
//}


/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet
   480-less    - phone landscape & smaller
--------------------------------------------*/


/*@media all and (min-width: 1024px) and (max-width: 1280px) {*/

/*}*/

/*@media all and (min-width: 768px) and (max-width: 1024px) {*/

/*  .section-main-content-wrapper {*/
/*    padding: 0px 32px 5vh 32px !important;*/
/*  }*/

/*  .section-content-wrapper {*/
/*    padding: 8vh 32px!important;*/
/*  }*/

/*}*/

/*@media all and (min-width: 480px) and (max-width: 768px) {*/

/*  .section-content-wrapper {*/
/*    padding: 8vh 48px!important;*/
/*  }*/

/*}*/

/*@media all and (max-width: 480px) {*/

/*  .section-main-content-wrapper {*/
/*    !*max-width: 1554px !important;*!*/
/*    !*background: red !important;*!*/
/*    padding: 0px 24px !important;*/
/*  }*/

/*  .section-content-wrapper {*/
/*    padding: 7vh 32px!important;*/
/*  }*/


/*}*/
