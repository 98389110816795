.under-construction-wrapper {
    background: #EDF8F8;
    color: #172D39;
    padding: 10vh 10vw;
    font-size: 23px;
    height: 100% !important;
    font-weight: 500;
    line-height: 29px;
    /*background: grey;*/
    /*background: #f6fcfc;*/
}

.under-construction-footer-logo  {
    /*filter: invert();*/
    height: 32px;
    margin-left: -4px;
    margin-bottom: 16px;
    /*margin-bottom: 24px;*/
}

.under-construction-wrapper > div {
    font-size: 16px;
    font-weight: 300;
}


/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet
   480-less    - phone landscape & smaller
--------------------------------------------*/


@media all and (min-width: 1024px) and (max-width: 1280px) {

}

@media all and (min-width: 768px) and (max-width: 1024px) {


}

@media all and (min-width: 480px) and (max-width: 768px) {


}

@media all and (max-width: 480px) {




}