@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.subscribeSectionForm {

    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px 0px;
    max-width: 440px;

    .subscribeInput {

        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        input {
            box-sizing: border-box;
            border: 1px solid rgba(38, 94, 95, 0.24);
            border-radius: 7px;
            background-color: #FFFFFF;
            box-shadow: 0 12px 24px -2px rgba(14, 59, 73, 0.1) !important;
            //box-shadow: 0 16px 16px 4px rgba(14, 59, 73, 0.1) !important;
            //opacity: 0.45;
            color: #576464;
            font-family: "Work Sans";
            letter-spacing: 0;
            //text-align: center;
            padding: 24px 32px;
            border: 0px;


            /* identical to box height */


            color: #576464;

            @include for-tablet-portrait-up {
                box-shadow: 0 16px 16px 4px rgba(14, 59, 73, 0.1) !important;
            }
        }

        @include for-phone-only {
            // grid-column: 1/5;
            // height: 75px;
            // font-size: 16px;
            // line-height: 19px;
        }

        @include for-tablet-portrait-up {
            // grid-column: 1/4;
            // height: 75px;
            // font-size: 16px;
            // line-height: 19px;
        }

        @include for-desktop-up {
            // grid-column: 3/8;
            // height: 75px;
            // font-size: 16px;
            // line-height: 19px;
        }

        input:hover {
            box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
                        0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
        }

        input:focus {
            border: 0px;
            color: #F08769 !important;

            @include for-tablet-portrait-up {
                box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
                0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
            }
        }

    }


    ::placeholder {
        //opacity: 1;
      opacity: 0.45;
        //z-index: 2;
        color: #576464 !important;
    }



    .subscribeSectionCtaButton {
        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: "Work Sans" !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;

        height: 68px;
        padding: 24px 64px;

        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-right: 0px;
    }

    .subscribeSectionCtaButton:hover {
        color: white;
        font-size: 15px !important;
        line-height: 20px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;
    }
}

.subscribeInfo {
    color: #F08769;
    font-family: "Work Sans";
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;

    //@include for-tablet-portrait-up {
        font-size: 16px;
        line-height: 28px;
    //}

    strong {
        font-weight: 500;
    }
}

.agreementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    margin-top: 4px;
    padding: 4px 4px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;


color: #505A5E;

    input {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        background: #FFFFFF;       
        border: 1px solid #EAEBEB;
        border-radius: 7px; 
    }

    input:hover {
        cursor: pointer;
    }
}

.agreementWrapper.error {
    border: 1px solid red;
    border-radius: 7px; 
}

.subscribeError {
    color: #fd4949;
    font-size: 14px;
    margin: 12px 0px;
}

.subscribeSuccess {
    color: #037860;
    font-size: 18px;
    margin: 24px 0px;
}