@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.section-content.footer-section {

    display: grid;
    padding-top: 0px;

    @include for-phone-only {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }

    @include for-tablet-portrait-up {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 30px;
    }

    @include for-desktop-up {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 30px;
    }

}

.contact-info {

    @include for-phone-only {
        grid-column: 1/5;
    }

    @include for-tablet-portrait-up {
        grid-column: 1/3;
    }

    @include for-desktop-up {
        grid-column: 1/4;
        //background: blue;
    }

    color: #0E3B49;
    font-family: "Work Sans";
    letter-spacing: 0;

    @include for-desktop-up {
        font-size: 14px;
        line-height: 24px;
    }

    a {
        color: #0E3B49;
    }

    .contact-info-logo {
        width: 80px;
        margin-bottom: 24px;
        margin-left: -4px;
    }

}

.footer-info {

    @include for-phone-only {
        grid-column: 1/5;
        //background: yellow;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    @include for-tablet-portrait-up {
        grid-column: 3/7;
        //background: yellow;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @include for-desktop-up {
        grid-column: 6/13;
        //background: yellow;
        display: flex;
        justify-content: space-between;
    }

    .footer-col {

        @include for-phone-only {
            flex: 1 50%;
        }

        @include for-tablet-portrait-up {
            flex: 1 50%;
        }

        @include for-desktop-up {
            flex: 1;
        }

        .footer-col-header {
            color: #265E5F;
            font-family: "Work Sans";
            font-weight: 600;
            letter-spacing: 1px;

            @include for-desktop-up {
                font-size: 14px;
                line-height: 16px;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                color: #576464;
                font-family: "Work Sans";
                letter-spacing: 0;

                line-height: 16px;
                font-size: 14px;
                margin-top: 16px;
            }

            li:hover {
                cursor: pointer;
            }
        }
    }

    .footer-col:nth-of-type(n+3) {

        @include for-phone-only {
            margin-top: 24px;
        }

        @include for-tablet-portrait-up {
            margin-top: 24px;
        }

        @include for-desktop-up {
            margin-top: 0px;
        }

    }
}