@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.menu-section {
    /*background: #EDF8F8;*/
    display: flex;
    align-items: center;
}

.menu-section .push {

    //@include for-desktop-up {
        margin-left: auto;
    //}

}

.menu-buttons {
    display: flex;
    justify-content: right;
    align-items: center;
}

.menu-cta-button {
    color: #58564F !important;
    font-family: "Work Sans" !important;
    z-index: 10;
    border: 1px solid #EDF8F8 !important;
    border-radius: 7px !important;
    background-color: #DFDDD5 !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;

    @include for-phone-only {
        display: none !important;
    }

    @include for-tablet-portrait-up {
        display: none !important;
    }

    @include for-desktop-up {
        display: inline !important;
        height: 48px;
        width: 172px;
        font-size: 14px !important;
        line-height: 16px !important;
    }


}

.menu-cta-button:hover {
    // color: white !important;
    font-weight: 700 !important;
}

.mobile-menu {

    display: none !important;
    opacity: 0.5;

    @include for-phone-only {
        display: flex !important;
        align-items: center !important;
    }
}

.desktop-menu {

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: inline;
    }

    @include for-desktop-up {
        margin-right: 50px;
    }

}

.desktop-menu > .ui.text.menu {
    display: flex;
    justify-content: right;
    align-items: center;
    justify-content: space-between;
    color: #576464;
    font-family: "Work Sans";
    letter-spacing: 0;
    margin: 0px 0px 0px 0px;

    .item {
        color: #576464;
    }

    .item:hover {
        color: #000000;
        font-weight: 500;
        transform: scale(1.06);
    }

    @include for-tablet-portrait-up {
        width: 513px;
        height: 16px;
        font-size: 14px;
        line-height: 16px;
    }

    @include for-desktop-up {
        width: 514px;
        font-size: 14px;
        line-height: 16px;
        /*background: yellow !important;*/
        /*margin-right: 50px;*/
    }

}

.menu-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-top-wrapper .ui.text.menu .item {

    color: lightgrey !important;

    @include for-desktop-up {
        font-weight: 500 !important;
        font-size: 16px;
        padding: 0px 16px;
    }

}

.menu-top-wrapper .ui.text.menu a {
    display: flex;
}


.menu-logo {
    height: 31px;

    // filter: invert(100%);

    @include for-desktop-up {

    }

}

//.mobile-menu {
//    display: none !important;
//    opacity: 0.5 !important;
//}

///*------------------------------------------
//  Responsive Grid Media Queries - 1280, 1024, 768, 480
//   1280-1024   - desktop (default grid)
//   1024-768    - tablet landscape
//   768-480     - tablet
//   480-less    - phone landscape & smaller
//--------------------------------------------*/
//
//
//@media all and (min-width: 1024px) and (max-width: 1280px) {
//
//}
//
//@media all and (min-width: 768px) and (max-width: 1024px) {
//
//    .menu-top-wrapper .ui.text.menu .item {
//        font-size: 14px;
//    }
//
//    .desktop-menu {
//        display: none;
//    }
//
//    .mobile-menu {
//        display: unset !important;
//    }
//
//    .menu-top-wrapper {
//        padding: 32px 10vw;
//    }
//
//    .menu-logo {
//        margin-left: 4px;
//    }
//
//}
//
//@media all and (min-width: 480px) and (max-width: 768px) {
//
//    .menu-logo {
//        margin-left: 24px;
//    }
//
//    .desktop-menu {
//        display: none;
//    }
//
//    .mobile-menu {
//        display: unset !important;
//    }
//
//}
//
//@media all and (max-width: 480px) {
//
//    .desktop-menu {
//        display: none;
//    }
//
//    .mobile-menu {
//        display: unset !important;
//    }
//
//    .menu-top-wrapper {
//        padding: 24px;
//    }
//
//    .menu-logo {
//        height: 24px;
//        margin-left: 16px;
//    }
//
//
//
//}


// .mobile-menu {
//     position: static;
//     top: 0;
//     left: 0;
//     z-index: 100;
//     background: white;
//     width: 100vw;

//     @include for-desktop-up {
//         display: none;
//     }
// }

// .mobile-menu-content {
//     background: white;
//     position: absolute;
//     z-index: 100;
//     width: 100%;
// }