@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.under-construction-section-content-wrapper {
    margin-top: 97px;

    @include for-phone-only {
        margin-top: 47px;
    }

    @include for-tablet-portrait-up {
        margin-top: 0px;
    }
}

.content-wrapper {
    background: linear-gradient(180deg, #EDF8F8 0%, #FFFFFF 100%), #FFFFFF;
}

.under-construction-section.section-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    // background: black;
}

.under-construction-section.section-content {

    z-index: 7 !important;
    position: relative;

    @include for-phone-only {
        //padding-top: 40px;
    }

    @include for-tablet-portrait-up {
        //padding-top: 40px;
    }

    @include for-desktop-up {
        //padding-top: 80px;
    }
}

.under-construction-section.section-content > .grid {
    margin: 0px !important;

    @include for-phone-only {
        margin-top: 233px !important;
    }

    @include for-tablet-portrait-up {
        margin-top: 80px !important;
    }

    @include for-desktop-up {
        margin-top: 60px !important;
    }
}

.under-construction-section.section-content > .grid {
    display: flex;
}

.under-construction-section.section-content > .grid > .padding-col {

    @include for-phone-only {
        flex: 0;
    }

    @include for-tablet-portrait-up {
        flex: 0;
        /*background: black;*/
    }

    @include for-desktop-up {
        flex: 1;
        /*background: black;*/
    }

}

.under-construction-section.section-content > .grid .content-cols-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-tablet-portrait-up {
        flex: 6;
        //background: black;
        //overflow-x: hidden;
    }

    @include for-desktop-up {
        flex: 10;
    }

}

.under-construction-section.section-content > .grid .content-col {
    display: flex;
    //justify-content: center;
    align-items: center;
    z-index: 2;

    // background: pink;

    @include for-tablet-portrait-up {
        flex: 3;
        //background: black;
        //overflow-x: hidden;
    }

    @include for-desktop-up {
        flex: 5;
    }
}

.under-construction-section.section-content > .grid .image-col {

    // background: blue;
    align-items: flex-start !important;

    @include for-phone-only {
        display: none !important;
    }

    @include for-tablet-portrait-up {
        visibility: hidden;
    }

    @include for-desktop-up {
        visibility: visible;
    }

}

.under-construction-section.section-content > .grid .image-col-tablet {

    position: absolute;

    //width: 50%;
    //height: 300px;

    margin-left: 50%;
    z-index: 1;


    @include for-phone-only {
        display: none !important;
    }

    @include for-desktop-up {
        display: none !important;
    }

    //img {
    //    //z-index: 1;
    //    //width: 50%;
    //    //object-fit: cover;
    //}

    .image-mask {
        position: absolute;
        left: 0;
        width: 40%;
        height: 100%;
        background: linear-gradient(270deg, rgba(237,248,248,0) 0%, #EDF8F8 100%);
        // background: linear-gradient(270deg, rgba(237,248,248,0) 0%, #0e3b49 100%);
        z-index: 2;
    }

}

#svg {
    position: absolute;
    z-index: 1;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: none;
        //min-width: 1231px;
        //transform: translate(220px, 50px);
    }

    @include for-desktop-up {
        display: initial;
        //transform: unset;
        //min-width: unset;
        max-width: 1231px;
    }

}

#svg-tablet {
    position: absolute;
    z-index: 1;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {

    }

    @include for-desktop-up {
        display: none;
    }

}

#svg-mobile {
    position: absolute;
    z-index: 1;

    @include for-tablet-portrait-up {
        display: none;
    }
}

#svg2 {
    position: absolute;
    z-index: 2;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: none;
        //min-width: 1331px;
        //transform: translate(220px, -50px);
    }

    @include for-desktop-up {
        display: initial;
        //transform: unset;
        //min-width: unset;
        max-width: 1331px;
        margin-top: -8px;
    }

}

#svg2-tablet {
    position: absolute;
    z-index: 2;

    @include for-phone-only {
        display: none;
    }

    //@include for-tablet-portrait-up {
    //    min-width: 1331px;
    //    transform: translate(220px, -50px);
    //}

    @include for-desktop-up {
        display: none;
    }

}

#svg2-mobile {
    position: absolute;
    z-index: 2;

    @include for-tablet-portrait-up {
        display: none;
    }
}

.under-construction-section-header {

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    color: #153F4C;
    max-width: 445px;

    @include for-phone-only {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        //padding: 0 8px;
        margin-bottom: 16px;

        margin-top: 28px;
        color: #0E3B49;

    }

    @include for-tablet-portrait-up {
        width: 450px;
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 24px;
    }

    @include for-desktop-up {
        width: 465px;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 24px;
    }

    div {
        color:#27A5A5;
    }

}

.under-construction-section-subheader {

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #505A5E;

    @include for-phone-only {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;

        text-align: center;
        color: #576464;
    }

    @include for-tablet-portrait-up {
        //height: 28px;
        width: 381px;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    @include for-desktop-up {
        //height: 28px;
        width: 381px;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    br {
        @include for-tablet-portrait-up {
            display: none !important;
        }
    }
}

.under-construction-section-cta-button {

    color: #EDF8F8 !important;
    // color: #0E3B49 !important;
    font-family: "Work Sans" !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    border-radius: 7px !important;
    background-color: #0E3B49 !important;
    // background-color: #EDF8F8 !important;
    text-align: center !important;
    animation: ease-in !important;
    animation-duration: 5s !important;
    transition: 0.3s !important;

    @include for-phone-only {
        width: 100%;
        height: 64px;
        border-radius: 7px;

        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;


    }

    @include for-tablet-portrait-up {
        height: 74px;
        width: 237px;
        border-radius: 7px;
        background-color: #0E3B49;
    }

    @include for-desktop-up {
        height: 74px !important;
        width: 237px !important;
        font-size: 14px !important;
        line-height: 16px !important;
    }
}

.under-construction-section-cta-button:hover {
    font-size: 15px !important;
    line-height: 20px !important;
    background-color: #f99066 !important;
    color: white !important;
}

.under-construction-section-image-content {
    display: flex;
    justify-content: right;
    align-items: flex-start;
}

.under-construction-section-image-content > .ui.image {

    height: 100%;
    width: 100%;

    @include for-tablet-portrait-up {
        //padding: 15px 5px;
    }


    @include for-desktop-up {
        // padding: 15px 5px;
    }


}

.under-construction-section-image-content.mobile-only {
    position: absolute;
    //background: #33445580;

    display: none;
    z-index: 1;

    @include for-phone-only {
        display: inline;
    }

    img {
        margin-top: 24px;
    }

    .image-mask {
        position: absolute;
        z-index: 2;
        
        // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
        background: linear-gradient(180deg, #EDF8F8 0%, #FFFFFF 100%);
        width: 100%;
        height: 50%;
        bottom: 0;
    }

}

.under-construction-footer-wrapper {
    // border: 1px solid black;
    background: white;
    padding-top: 80px;
}