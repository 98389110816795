@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}


.usage-section.section-content {

    //text-align: center;
    overflow-x: hidden;
    //padding-top: 0px;
    //padding-bottom: 0px;

    @include for-tablet-portrait-up {
        padding-top: 0px;
    }

}

.usage-header {

    font-family: "Work Sans";
    font-weight: 600;
    letter-spacing: 0;
    color: #0E3B49;
    margin: 0 auto;
    width: 100%;
    text-align: center;


    @include for-phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 80px;
    }

    @include for-tablet-portrait-up {
        line-height: 44px;
        font-size: 40px;
        margin-bottom: 96px;
    }

}

.usage-row {
    width: 150vw;
    height: 80px;
    //background: pink;
    display: flex;
    //align-items: center;
    justify-content: left;
    margin-bottom: 120px;
    //justify-content: space-between;
}

.usage-items {
    display: grid;

    @include for-tablet-portrait-up {
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 20px;
    }

    @include for-desktop-up {
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 120px;
    }

}

.usage-item {
    //flex: 1 20%;

    display: flex;
    //background: yellow;
    height: 97px;
    align-items: center;
    width: max-content;
    //margin-right: 84px;
    padding-right: 40px;

    .image {
        z-index: -1;
        max-width: 100%;
        height: auto;

        @include for-phone-only {
            height: 64px;
        }
    }

    .text {
        color: #0E3B49;
        font-family: "Work Sans";
        font-weight: 600;
        letter-spacing: 0;

        @include for-phone-only {
            line-height: 25px;
            font-size: 20px;
            margin-left: -24px;
        }

        @include for-desktop-up {
            line-height: 28px;
            font-size: 24px;
            margin-left: -16px;
        }
    }

}


@include for-phone-only {
    .usage-item:nth-of-type(odd) {
        padding-left: 0px;
    }
    .usage-item:nth-of-type(even) {
        padding-left: 64px;
    }
}

@include for-tablet-portrait-up {
    .usage-item:nth-of-type(n) {
        grid-row: 1;
    }
    .usage-item:nth-of-type(n+3) {
        grid-row: 2;
    }
    .usage-item:nth-of-type(n+4) {
        grid-row: 2;
    }
    .usage-item:nth-of-type(n+5) {
        grid-row: 3;
    }
    .usage-item:nth-of-type(2) {
        padding-left: 44px;
    }
    .usage-item:nth-of-type(3) {
        padding-left: 84px;
    }
    .usage-item:nth-of-type(4) {
        padding-left: 84px;
    }
    .usage-item:nth-of-type(5) {
        padding-left: 44px;
    }
}

@include for-desktop-up {
    .usage-item:nth-of-type(n) {
        grid-row: 1;
        margin-right: 24px;
    }
    .usage-item:nth-of-type(n+3) {
        grid-row: 2;
    }
}


//.usage-item:nth-of-type(n+4) {
//    margin-top: 120px;
//    padding-left: 84px;
//}
//
//.usage-row:nth-of-type(2) {
//
//
//    @include for-desktop-up {
//
//        margin-left: 82px;
//
//        .usage-item {
//            margin-right: 150px;
//        }
//
//    }
//
//}


[data-aos="new-slide-left"] {
    margin-left: 2000px;
    transition-property: margin-left;

    &.aos-animate {
        margin-left: 0;
    }
}

[data-aos] {
    body[data-aos-easing="new-easing"] &,
    &[data-aos][data-aos-easing="new-easing"] {
        transition-timing-function: cubic-bezier(.35,1.11,1,.66);
    }
}

//cubic-bezier(.26,.87,.96,.12);
//cubic-bezier(.13,1.04,1,.4)
