@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.sectionWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 80px 0px;

    @include for-phone-only {
        padding: 0px 0px 56px 0px;
    }

    @include for-tablet-portrait-up {
        padding: 80px 24px;
    }
}

.tabletOnly2 {
    display: none !important;

    @include for-tablet-portrait-up {
        display: unset !important;
    }

    @include for-tablet-landscape-up {
        display: none !important;
    }

    @include for-desktop-up {
        display: none !important;
    }
}

.sectionContent {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.sectionHeader {
    color: #0E3B49;
    font-family: "Work Sans";
    font-weight: 600;
    letter-spacing: 0;
    max-width: 660px;

    @include for-phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 22px;
        max-width: 320px;
    }

    @include for-tablet-portrait-up {
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        margin-bottom: 24px;
    }

    @include for-desktop-up {
        font-size: 48px;
        line-height: 52px;
    }

}

.description {

    color: #0E3B49;
    font-family: "Work Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    // border: 1px solid red;

    @include for-phone-only {
        // font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
        padding: 0px 30px 0px 40px;
        // background: pink;
        text-align: left;
    }

    @include for-tablet-portrait-up {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 64px;

        br {
            display: inline;
        }

        .mobile-only {
            display: none;
        }

        .tablet-only {
            display: inline;
        }

    }

    @include for-desktop-up {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
        margin-bottom: 48px;

        br {
            display: inline;
        }

        .mobile-only {
            display: none;
        }

        .tablet-only {
            display: inline;
        }
    }

    .email {
        display: inline-block;
        width: min-content;
        border-radius: 3px;
        background-color: #0E3B49;
        padding: 4px 8px;  

        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        @include for-tablet-portrait-up {
            margin-top: 12px;
        }

        @include for-desktop-up {
            margin-top: 0px;
        }
    }

    .login {
        display: inline-block;
        width: min-content;
        border-radius: 3px;
        background-color: #5EDED2;
        padding: 4px 8px;  

        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .password {
        display: inline-block;
        width: min-content;
        border-radius: 3px;
        background-color: #DFDDD5;
        padding: 4px 8px;  

        color: #808080;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .name {
        display: inline-block;
        width: min-content;
        border-radius: 3px;
        background-color: #FF977A;
        padding: 4px 8px;  

        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .phone {
        width: max-content;
        display: inline-block;
        border-radius: 3px;
        background-color: #F06969;
        padding: 4px 8px;  

        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .card {
        width: max-content;
        display: inline-block;
        border-radius: 3px;
        background-color: #6F729C;
        padding: 4px 8px;  

        color: #FFFFFF;
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;

        @include for-phone-only {
            line-height: 18px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

}

.description > div:first-child {
    margin-bottom: 16px;
}


.important {
    padding: 48px 95px;
    border-radius: 7px;
    background-color: #FFF5F2;
    max-width: 920px;

    @include for-phone-only {
        max-width: 320px;
        margin: 0 auto;
        padding: 36px 25px;
    }
}


.importantHeader {
    color: #F08769;
    font-family: "Work Sans";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 28px;

    @include for-phone-only {
        // text-align: left;
    }
}

.importantDescription {
    color: #313131;
    font-family: "Work Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;

    @include for-phone-only {
        text-align: left;
        font-size: 15px;
    }
}

.highlight {
    text-decoration: underline;
    margin-left: 4px;
}