
.timer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    // border: 1px solid black;
    margin-bottom: 24px;
}

.time-square {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 66px;
    height: 78px;
    background: #DFDDD5;
    border-radius: 12px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #58564F;
}

.time-square-label {
    margin-top: 4px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #959286;
}